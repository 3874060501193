@font-face 
{
    font-family: 'montserrat-medium';
    src: local('montserrat-medium'), url(../Resources/fonts/Montserrat-Medium.ttf) format('truetype');
}
/*  Overlay Style*/

/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #eaeaea; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 30px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }


.overlay {
    font-family: 'montserrat-medium';
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    overflow-x: hidden;
    transition: 0.5s;
    color:white;
  }
  
.overlaycontent {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
.overlay li{
    padding: 8px;
    font-size:36px;
    display: block;
    transition: 0.3s;
  }
.overlay a{
  color:white;
  }

.overlay a:hover{
    background-color: #333333;
    border-radius: 30px;

    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
    color:white;
    text-decoration: none;
}
.overlay .closebtn {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }

.hamburger
{
cursor: pointer;
display: none;
color:white;
font-size: 30px;
}

.linkNavbar:hover{
    background-color: #333333;
    border-radius: 30px;
    color:white;
}
.linkNavbar{

    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 7px;
    padding-top: 7px;
}

.Container
{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows:50px auto;
    min-height: 100vh;
    background-color:#eaeaea;
    font-family:'montserrat-medium';
    position: relative;
    min-height: 100vh;
    padding-bottom: 150px;
}


.navbarcontainer
{
    height:45px;
    grid-column: auto/span 17;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:white;
    padding: 10px;
    background-color:black;
    -webkit-box-shadow: 0px 3px 5px -3px rgba(207,207,207,1);
    -moz-box-shadow: 0px 3px 5px -3px rgba(207,207,207,1);
    box-shadow: 0px 3px 5px -3px rgba(207,207,207,1);
}

.logocol a:hover{
    color:white;
}
.logocol a
{   display: flex;
    align-items:center;
    text-decoration: none!important;
    list-style:none;  
    color:white;
    font-weight: 700;
    outline:none;
}
.logocol>a>span{
    margin:5px;
}
   

.navlist ul
{
    display: flex;
    flex-direction: row;
    margin:0 auto; /*clears bottom-margin UL items*/
}

.navlist ul li
{
    list-style-type:none;
    margin-right:20px;
}

.navlist a
{
    font-size:14px;
    color:white;
    text-decoration:none!important;
    border-radius:20px;
    outline:none;
}


.contentcontainer{
    grid-column: auto /span 12;
} 

.footerbasic {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    padding: 20px 0;
    background-color:#eaeaea;
    color:#4b4c4d;
}

.footerbasic .listinline {
    display: flex;
    justify-content: center;
    align-items: center;
    border-spacing: 10px;
    margin: 0 20px 10px 20px;
}

.footerbasic ul {
    padding:0;
    list-style:none;
    text-align:center;
    font-size:18px;
    line-height:1.6;
    margin-bottom:0;
}

.footerbasic li {
    padding:0 10px;
}

.footerbasic ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.8;
}

.footerbasic ul a:hover {
    opacity:1;
}

.footerbasic .copyright {
    margin-top:10px;
    text-align:center;
    font-size:13px;
    color:#aaa;
    margin-bottom:0;
}


/*Responsive*/
@media(max-height:600px){
    .heading{
        font-size: 30px;
    }
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
}
@media(max-width:767px){
    .navlist{
        display:none;
    }
    .hamburger{
        display: block;
    }
}
@media(min-width:1200px){
    .logocol {
        padding-left: 5rem;
    }
}
@media(max-width:505px){
   .footerbasic ul {
        font-size: 1rem;
    }

    .footerbasic .listinline {
        display: block;
    }

    .footerbasic {
        height: 180px;
    }

    .Container {
        padding-bottom: 180px;
    }
}