.reactionContainer{
    max-width: 50px !important;
    height:100%;
    background-color:transparent;
    position:relative;
    margin-right: 3px;
}
.emojiContainer{
    cursor:pointer;
    background-color:#eaeaea;
    width: 50px;
    position: absolute;
    bottom: 0px;
}

.emojiContainerNotLoggedIn{
    background-color:#eaeaea;
    width: 50px;
    position: absolute;
    bottom: 0px;
}

.reactNum{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5rem;
    font-size: 1rem;
}

.rowContainer{
    height: 200px;
    overflow: hidden;
}

.emojiRow{
    height: 200px;
    max-width: 320px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.emojiContainer:hover {
    border-bottom: 2px solid #BFD7EA;
}

.emojiContainerVoted{
    cursor:pointer;
    background-color:#eaeaea;
    width: 50px;
    position: absolute;
    bottom: 0px;
    border-bottom: 2px solid #BFD7EA;
}

.emojiImage{
    height: 50px;
    width: 50px;
    font-size: 20px;
    padding: 10px;
    position: relative;
}
.emojiImageVoted{
    height: 50px;
    width: 50px;
    padding: 12px;
    position: relative;
}

.voteBar{
    position: relative;
    width: 50px;
}

@media only screen and (max-width: 470px) {

    .reactionContainer{
        max-width: 40px !important;
    }
    .emojiContainer{
        height: 40px;
        width: 40px;
        bottom: 10px;
    }

    .emojiContainerNotLoggedIn{
        height: 40px;
        width: 40px;
        bottom: 10px;
    }

    .emojiContainerVoted{
        height: 42px;
        width: 40px;
        bottom: 10px;
    }
    .emojiImage{
        height: 40px;
        width: 40px;
        font-size: 15px;
    }
    .emojiImageVoted{
        height: 40px;
        width: 40px;
    }

    .voteBar{
        width: 40px;
    }

    .reactNum{
        font-size: 0.9rem;
    }

}
@media only screen and (max-width: 395px) {

    .reactionContainer{
        max-width: 30px !important;
    }
    .emojiContainer{
        height: 30px;
        width: 30px;
        bottom: 20px;
    }
    .emojiContainerNotLoggedIn{
        height: 30px;
        width: 30px;
        bottom: 20px;
    }

    .emojiContainerVoted{
        height: 32px;
        width: 30px;
        bottom: 20px;
    }
    .emojiImage{
        height: 35px;
        width: 35px;
        font-size: 15px;
        padding: 5px;
        left: -2.5px;
    }
    .emojiImageVoted{
        height: 35px;
        width: 35px;
        left: -2.5px;
    }

    .voteBar{
        width: 30px;
    }

    .reactNum{
        font-size: 0.8rem;
    }

}

@media only screen and (max-width: 300px) {

    .reactionContainer{
        max-width: 20px !important;
    }
    .emojiContainer{
        height: 20px;
        width: 20px;
        bottom: 30px;
    }
    .emojiContainerNotLoggedIn{
        height: 20px;
        width: 20px;
        bottom: 30px;
    }

    .emojiContainerVoted{
        height: 22px;
        width: 20px;
        bottom: 30px;
    }
    .emojiImage{
        height: 35px;
        width: 35px;
        font-size: 12px;
        padding: 0;
        left: -7.5px;
    }
    .emojiImageVoted{
        height: 35px;
        width: 35px;
        left: -7.5px;
    }

    .voteBar{
        width: 20px;
    }

    .reactNum{
        font-size: 0.7rem;
    }

}