.global{
    max-width: 1200px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}
.contentwrapper{
    width:calc(75% - 1.3rem);
    background-color: white;
    padding:15px;
    border-radius: 15px;
    overflow-wrap: break-word;
}

.CoinTitle{
    width: calc(100% - 105px);
    margin-left: 5px;
}

.LeftSide{
    width:calc(75% - 1.3rem);
    margin-right: 1.3rem;
}
.MainCard{
    background-color: white;
    padding:15px;
    overflow-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    height: 100%;
}
.RightSide{
    width: 25%;
}
.DataCard{
    background-color: white;
    padding:15px;
    overflow-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin-bottom: 1.3rem;
}
.LinksCard{
    background-color: white;
    padding:15px;
    overflow-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
.DescCard{
    background-color: white;
    padding:15px;
    overflow-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin-top: 0.5rem;
    display: none;
}

.DescDiv{
    height: calc(100% - 115px);
    position: relative;
}

.ReactDiv{
    bottom: 0;
    width: 100%;
    position: absolute;
}

.LinksCardMobile{
    margin-top: 0.5rem;
    display: none;
}

.LinksButton{
    width: 100%;
    margin: auto;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: left !important;
}
.LinksLabel {
    text-align: center;
    padding-left: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.LinksIcon{
    width: 23px;
    height: 23px;
    position: absolute;
    margin-right: 5px;
}
.table{
    margin: 20px;
    background-color: white;
    border-radius: 8px;
}

.TopRow{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    margin-bottom:5px;
}

.CommentList {
    max-height: 280px;
    overflow: auto;
    margin-bottom: 10px;
}

.CommentList::-webkit-scrollbar-track {
    background: #FFFFFF; 
}

.AddressContainer{
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 5px 8px;
    margin: 10px 0 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    background-color: #eff2f5;
    white-space: nowrap;
}

.AddressTitle{
    color: #58667e;
    padding: 0px 3px;
}

.DYOR{
    cursor: pointer;
    justify-content: center;
    white-space: unset;
}

.DYOR:hover {
    color: gray;
}

.Address{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.Address::before{
    box-sizing: border-box;
    border-spacing: 0px;
}

.CopyIcon{
    box-sizing: border-box;
    margin: 0px 0px 0px 4px;
    cursor: pointer !important;
    color: #808a9d;
    height: 16px;
    min-width: 14px;
    min-height: 14px;
}

.BackButton{
    font-size: 1rem;
    line-height: 1.5rem;
    color: black;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    width: 150px;
    margin-bottom: 0.2rem;
}

.InfoTitle{
    display: block;
    font-size: 1.2rem;
    color: #7889a0;
}

.InfoContent{
    font-weight: 550;
    font-size: 1.5rem;
    display: block;
    margin-bottom: 0.2rem;
}

.Buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width:30%;
}
.Description{
    margin: 10px 10px 10px 0;
    font-size: 0.9rem;
    text-align: left;
}

.Row {
    width: 100%;
    table-layout: fixed;
}
.ColumnRow {
    vertical-align: middle;
    display: table-cell;
    text-align: left;
}

.Column {
    flex: 1;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.ContractBot {
    display: none;
}

.LogoSize {
    width: 100px;
    height: 100px;
}

@media only screen and (max-width: 768px) 
{

.MainCard{
    height: unset;
}

.LinksCard {
    display: none;
}

.LinksCardMobile {
    display: block;
}

.DescCard {
    display: block;
}

.DescDiv{
    display: none;
}

.DataCard{
    margin-bottom: 0.5rem;
}

.RightSide{
    width: 100%;
    margin-top: 0.5rem;
}
.LeftSide{
    width: 100%;
    margin-right: 0;
}
.contentwrapper{
    width:100%;
    margin-right: 0;
}

}

@media only screen and (max-width: 400px) {
   
    h3 {
        font-size: 1rem !important;
    }

    .Description {
        font-size: 0.8rem;
    }

    .ContractTop {
        display: none;
    }

    .ContractBot {
        display: unset;
    }

    .LogoSize {
        width: 50px;
        height: 50px;
    }

    .global{
        padding-left: 5px;
        padding-right: 5px;
    }

    .CoinTitle{
        width: calc(100% - 55px);
    }

}
  