.global{
    margin-top:20px;
    width:70%;
    margin-left: 15%;
    margin-bottom:15px;
}
.contentwrapper{
    width:calc(75% - 1.3rem);
    background-color: white;
    padding:15px;
    border-radius: 15px;
    overflow-wrap: break-word;
}

.RightCard{
    width:25%;
    height:25%;
    background-color: white;
    padding:15px;
    border-radius: 15px;
    overflow-wrap: break-word;
}
.LeftCard{
    width:calc(75% - 1.3rem);
    margin-right: 1.3rem;
    background-color: white;
    padding:15px;
    border-radius: 15px;
    overflow-wrap: break-word;
}
.table{
    margin: 20px;
    background-color: white;
    border-radius: 8px;
}

.TopRow{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    margin-bottom:5px;
}

.CommentList {
    max-height: 280px;
    overflow: auto;
    margin-bottom: 10px;
}

.CommentList::-webkit-scrollbar-track {
    background: #FFFFFF; 
}

.AddressContainer{
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 5px 8px;
    margin: 10px 0 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    background-color: #eff2f5;
}

.AddressTitle{
    color: #58667e;
    padding: 0px 3px;
}

.Address{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.Address::before{
    box-sizing: border-box;
    border-spacing: 0px;
}

.CopyIcon{
    box-sizing: border-box;
    margin: 0px 0px 0px 4px;
    cursor: pointer !important;
    color: #808a9d;
    height: 16px;
    min-width: 14px;
    min-height: 14px;
}

.BackButton{
    font-size: 1rem;
    line-height: 1.5rem;
    color: black;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    width: 150px;
    margin-bottom: 0.2rem;
}

.InfoTitle{
    display: block;
    font-size: 1.2rem;
    color: #7889a0;
}

.InfoContent{
    font-weight: 550;
    font-size: 1.5rem;
    display: block;
    margin-bottom: 0.2rem;
}
.LeftPush{
    margin-left:20%;
}

.Buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width:30%;
}
.Description{
    margin: 10px 10px 10px 0;
    font-size: 0.9rem;
    text-align: left;
}

.Row {
    width: 100%;
    table-layout: fixed;
}
.ColumnRow {
    vertical-align: middle;
    display: table-cell;
    background-color: "white";
    text-align: left;
}

.Column {
    flex: 1;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}


@media only screen and (max-width: 768px) 
{
    
.global{
    margin-top:50px;
    width: 80%;
    margin-left: 10%;
    margin-bottom:15px;
}

.RightCard{
    width: 100%;
    margin-top: 1.3rem;
}
.LeftCard{
    width: 100%;
    margin-right: 0;
}
.contentwrapper{
    width:100%;
    margin-right: 0;
}

}

@media only screen and (max-width: 400px) {
   
    h3 {
        font-size: 1rem !important;
    }

}
  