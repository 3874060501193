.contentwrapper{
    width:100%;
    background-color: transparent;
    overflow-wrap: break-word;
    text-align: center;
}

.MainContainer{
    max-width: 1200px;
    margin: auto;
}

p {
    margin: 0;
    margin-bottom: 0 !important;
}

.mainrow{
    align-items: center;
}

.firstcol{
    grid-column: auto/span 2;
}

.table{
    margin: 20px;
    margin-top: 0px;
    background-color: white;
    border-radius: 8px;
    border-top-left-radius: 0;
    align-self: center;
    justify-self: center;
}

.tableTrending{
    margin: 20px;
    margin-top: 0px;
    background-color: white;
    border-radius: 8px;
    align-self: center;
    justify-self: center;
}

.secondcol{
    grid-column: auto/span 5;
}

.stock_illustration{
    max-width: 100%;   
}
.heading{
    color:#5efce8;
}

.RowContain{
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;

}
.startICon{
    margin: 0;
}
.RowContain:hover{
    background-color: #F2F5F7;
}
.RowContain:hover:first-of-type{
    background-color: #F2F5F7;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
.RowContain:hover:last-of-type{
    background-color: #F2F5F7;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.RowContain:last-of-type {
    border-bottom: none;
}
.ColFontSize {
    font-size: 0.9rem;
}

.InlinePromoted{
    font-size: 0.7vw;
    color:blue;
    float:left;
    position:absolute;
}

.ShowAll {
    margin-bottom: 0;
    display: contents;
    font-size: 0.8rem;
}

.RowShowAll {
    cursor: pointer;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-spacing: 10px;
}

.RowShowAllLoader {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-spacing: 10px;
}

.RowShowAll:hover {
    text-decoration: underline;
}

.TopColumn {
    font-weight: 700;
}

.TopTitle{
    font-size: 4vw;
    font-weight: 700;
}

.TopTitleDiv {
    align-self: left;
    text-align:left;
    margin: 15px;
    padding-left:10px;
    margin-bottom: 8px;
}

.navrow a{
    border:2px solid #5EFCE8;
    color:black;
    padding:5px 10px;
    text-decoration: none!important;
    border-radius:20px;
    border-color: #eaeaea;
}

.navbarcontainer{
    text-align: start;
    color:white;
    padding: 10px;
    background-color:#fff;
    -webkit-box-shadow: -1px 27px 26px -20px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 27px 26px -20px rgba(0,0,0,0.75);
    box-shadow: -1px 27px 26px -20px rgba(0,0,0,0.75);
}

.paralogin{
margin-top:20px;
font-size:10px;
}

.VoteButton {
    width: 80px;
    height: 40px;
}

.CoinLogo {
    width: 40px;
    height: 40px;
}

.VoteIcon {
    width: 19px;
    height: 19px;
}

.VoteText {
    font-size: 0.7rem;
    margin-top: 1px;
}

.MenuTabs {
    margin-top:6px;
    margin-left: 19px;
    margin-right: 19px;
    border-bottom: 0;
    font-size: 1rem;
}
nav > a{
color:#28a745;
}
nav > a:hover{
color:black;
}

.SmallName {
    display: none;
}

.RowLink {
    color: unset !important;
    text-decoration: unset !important;
    height: 100%;
}
.RowLink:hover {
    color: unset !important;
    text-decoration: unset !important;
}

/*Responsive*/
@media(max-height:600px)
{
    .heading{
        font-size: 30px;
    }
    .mainrow{
        margin-top:30px;
    }
    .para{
        font-size: 12px;
    }
}

@media only screen and (max-width: 768px) 
{
    
  .FullName {
      display: none;
  }

  .SmallName {
      display: unset;
  }

}

@media only screen and (min-width: 768px) {
   
.TopTitle{
    font-size: 1.3rem;
    font-weight: 700;
}

.ShowAll {
    font-size: 0.9rem;
}

.ColFontSize {
    font-size: 1rem;
}

.MenuTabs{
    margin-left: 19px;
    margin-right: 19px;
    border-bottom: 0;
    border-color: #eaeaea;
    font-size: 1.3rem;
}

}

@media only screen and (max-width: 580px) {
   
    .VoteButton {
        width: 60px;
        height: 40px;
        padding: 0 !important;
    }

    .VoteIcon {
        width: 15px;
        height: 15px;
    }

    .VoteText {
        font-size: 0.6rem;
        margin-top: 1px;
    }

    .MenuTabs {
        margin-left: 19px;
        margin-right: 19px;
        border-bottom: 0;
        font-size: 0.8rem;
    }

    .ColFontSize {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 380px) {
    .tableTrending {
        margin-right: 0;
        margin-left: 0;
        border-radius: 0;
    }

    .table {
        margin-right: 0;
        margin-left: 0;
        border-radius: 0;
    }

    .TopTitleDiv {
        margin-right: 0;
        margin-left: 0;
    }

    .MenuTabs {
        margin-right: 0;
        margin-left: -1px;
    }
}

@media only screen and (max-width: 350px) {

    .MenuTabs {
        font-size: 0.7rem;
    }

    .CoinLogo {
        width: 30px;
        height: 30px;
    }
}