.contentwrapper{
    text-align: left !important;
    align-self: center;
    justify-self: center;
    grid-template-columns: 1fr;
    grid-template-rows:auto;
    width: 60%;
    margin-left: 20%;
    padding-top: 20px;
    padding-bottom: 50px;
}

.image{
    height: 100px;
    width: 100px;
    border: 1px solid #212529;
    margin-top: 25px;
}

.BackButton{
    font-size: 1rem;
    line-height: 1.5rem;
    color: #7889a0;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    width: 150px;
    margin-bottom: 0.2rem;
}

.TopTitle{
    font-size: 4vw;
    font-weight: 700;
}

@media only screen and (min-width: 1000px) {
   
    .TopTitle{
        font-size: 1.3rem;
        font-weight: 700;
    }
}