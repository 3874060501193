.global{
    margin-top:100px;
    width:70%;
    margin-left: 15%;
}
.contentwrapper{
    width:100%;
    background-color: white;
    border-radius: 5px;
    overflow-wrap: break-word;
    
}

.TopRow{
    display:flex;
    flex-direction:row;
}

.TopRowLeft{
    text-align: left;
    width: 50%;
}

.TopRowRight{
    width: 50%;
    display:flex;
    flex-direction:column;
    padding-top:4%;
}
.Name{
    font-size: 22px;
    font-weight:600;
}
.TimeAndName{
    text-align: left;
    padding-top:5px;
}
.CommentContent{
    font-size: 12px;
    text-align:left;
}

@media only screen and (max-width: 768px) 
{
    

.TopRowLeft{
    text-align: left;
    width: 0%;      
    display:none;
}
    

.TopRowRight{
    width: 100%;
    display:flex;
    flex-direction:column;
    padding:4%;
    padding: 3%;
}

}
  