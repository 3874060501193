.contentwrapper{
    display: grid;
    align-self: center;
    justify-self: center;
    grid-template-columns: 1fr;
    grid-template-rows:auto;
    margin: 0px 100px;
}

.clickable{
    cursor: pointer;
}

.clickable:hover{
    background-color: #212529;
    color: #eaeaea;
}

.nameCell{
    max-height: 60px;
    overflow: hidden;
}

.pictoGreen{
    background-color: green;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 0 auto;
}

.pictoRed{
    background-color: red;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 0 auto;
}

th .down{
    content: '▼';
    display: inline-block;
    margin-left: 1em;
}

th .up{
    content: '▲';
    display: inline-block;
    margin-left: 1em;
}

th{
    cursor: pointer;
}

th:hover{
    color: #6486ff;
}