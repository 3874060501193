.contentwrapper{
    align-self: center;
    justify-self: center;
    grid-template-columns: 1fr;
    grid-template-rows:auto;
}

.FormLabel {
    font-size: 0.8rem;
    font-weight: 500;
    color: #3e4d61;
    margin-bottom: 0.3rem;
    padding-left: 1px;
}

.Container{
    max-width: 1200px;
    padding: 20px;
    margin: auto;
}

.TopTitle{
    font-size: 1.5rem;
    font-weight: 700;
}

.BackButton{
    font-size: 1rem;
    line-height: 1.5rem;
    color: black;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    width: 150px;
    margin-bottom: 0.2rem;
}
